import { Box, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';

const MONITOR_STATUS_URL = process.env.REACT_APP_MONITOR_STATUS_URL;

const MonitorButton = () => {
    const intl = useIntl();
    return (
        <Tooltip title={intl.formatMessage({ id: 'all-services-are-online' })} arrow>
            <Box
                onClick={() => window.open(`${MONITOR_STATUS_URL}`)}
                sx={{
                    position: 'relative',
                    width: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    backgroundImage: 'linear-gradient(30deg, #0400ff, #4ce3f7)',
                    '&:hover': {
                        cursor: 'pointer'
                    },
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '14px',
                        height: '14px',
                        borderRadius: '50%',
                        backgroundImage: 'linear-gradient(30deg, #0400ff, #4ce3f7)'
                    },
                    '&::after': {
                        animation: 'pulse 2s infinite ease-in-out'
                    },
                    '@keyframes pulse': {
                        from: {
                            transform: 'scale(0.7)',
                            opacity: 1
                        },
                        to: {
                            transform: 'scale(2)',
                            opacity: 0
                        }
                    }
                }}
            />
        </Tooltip>
    );
};

export default MonitorButton;
